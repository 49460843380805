import React, { useState } from "react";
import emailjs from "emailjs-com";
import TermsAndConditions from "../components/TermsAndConditions";

const SportsSessionPage = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [notes, setNotes] = useState("");
  const [emailError, setEmailError] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setEmailError("❌ Invalid email address");
      return;
    }

    setEmailError("");

    const emailContent = {
      from_name: name,
      from_email: email,
      message: notes,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        emailContent,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(() => {
        setSuccessMessage("✅ Email sent successfully!");
        setSubmitError("");
      })
      .catch(() => {
        setSuccessMessage("");
        setSubmitError("❌ Failed to send email. Please try again.");
      });
  };

  return (
    <div className="min-h-screen text-gray-800 mt-20 px-4 sm:px-8 py-12">
      <div className="max-w-3xl mx-auto bg-white shadow-lg rounded-lg p-6 md:p-10">
        <div className="bg-gray-800 text-white text-center py-6 rounded-md mb-6">
          <h2 className="text-2xl md:text-3xl font-bold">Sports Session</h2>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-3">📸 Session Details</h3>
          <ul className="list-disc list-inside text-gray-700 space-y-1">
            <li>One sports event</li>
            <li>Hand editing</li>
            <li>All edited images included</li>
            <li>No limit on delivered images</li>
            <li>Professional Stills</li>
            <li>
              <span className="font-bold">$200</span> + additional{" "}
              <span className="font-bold">$100 per hour</span>
            </li>
          </ul>
        </div>

        <p className="bg-gray-100 text-gray-800 px-6 py-3 rounded-md mb-6 max-w-lg">
          📍 If travel exceeds 30 minutes, an additional <b>$20</b> fee will be added.
        </p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-800 font-medium mb-1">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              maxLength={50}
              className="w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-blue-300"
            />
          </div>

          <div>
            <label className="block text-gray-800 font-medium mb-1">
              Email <span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength={100}
              className="w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-gray-300"
            />
            {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
          </div>

          <div>
            <label className="block text-gray-700 font-medium mb-1">
              Additional Notes
            </label>
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              maxLength={500}
              className="w-full p-3 border border-gray-300 rounded-md focus:ring focus:ring-gray-300"
              rows={4}
            />
            <p className="text-sm text-gray-500">
              Locations, special occasions, type of photography, etc.
            </p>
          </div>

          <TermsAndConditions
            termsAccepted={termsAccepted}
            handleTermsChange={handleTermsChange}
          />

          <button
            type="submit"
            className={`w-full text-white font-semibold py-3 rounded-md ${
              termsAccepted
                ? "bg-gray-600 hover:bg-gray-800"
                : "bg-gray-400 cursor-not-allowed"
            }`}
            disabled={!termsAccepted}
          >
            Submit
          </button>
        </form>

        {successMessage && (
          <p className="bg-green-100 text-green-700 px-6 py-3 mt-4 rounded-md">
            {successMessage}
          </p>
        )}
        {submitError && (
          <p className="bg-red-100 text-red-700 px-6 py-3 mt-4 rounded-md">
            {submitError}
          </p>
        )}
      </div>
    </div>
  );
};

export default SportsSessionPage;