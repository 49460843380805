import React, { useState, useEffect, lazy, Suspense } from 'react';
import axios from 'axios';
import SelectedWorkArt from '../components/SelectedWorkArt';
import LetsMakeSomethingTogether from '../components/LetsMakeSomethingTogether';
import Loading from '../components/Loading';
import { Link } from "react-router-dom";

const IntroContent = lazy(() => import('../components/IntroContent'));

const Home = () => {
  const [isPending, setIsPending] = useState(true);
  const [backgroundHero, setBackgroundHero] = useState(null);

  const API_URL = process.env.REACT_APP_CLOUDFLARE_WORKER_URL;
  const BEARER = process.env.REACT_APP_BEARER;
  const TARGET_IMAGE_ID = '0f8957c4-15f1-47b9-54e5-be5cef080b00';
  const VARIANT_NAME = 'Hero';

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${BEARER}`,
          },
        });

        if (
          response.data &&
          response.data.result &&
          response.data.result.images
        ) {
          const imageUrls = response.data.result.images
            .filter((image) => image.id === TARGET_IMAGE_ID)
            .map(
              (image) =>
                `https://imagedelivery.net/Z1o5jP3Z0wrLOM-5XDvR8A/${image.id}/${VARIANT_NAME}`
            );

          if (imageUrls.length > 0) {
            setBackgroundHero(imageUrls[0]);
          } else {
            console.error('No matching images found');
          }
        } else {
          console.error('Unexpected response structure:', response.data);
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();

    const timer = setTimeout(() => {
      setIsPending(false);
    }, 1000); // Simulate loading state

    return () => clearTimeout(timer);
  }, [API_URL, BEARER, TARGET_IMAGE_ID, VARIANT_NAME]);

  return (
    <div className='flex flex-col items-center bg-gray-800 relative'>
      {isPending && <Loading />}
      {!isPending && (
        <Suspense fallback={<Loading />}>
          <div className='relative w-full h-screen flex flex-col justify-center items-center z-10'>
            <div
              className='absolute inset-0 flex flex-col justify-center items-center bg-cover bg-center'
              style={{
                backgroundImage: `url(${backgroundHero || 'https://i.imgur.com/wWsWm97.jpg'})`,
              }}
            >
              <IntroContent />
            </div>
            <div
              className='absolute bottom-0 left-1/2 transform -translate-x-1/2 h-24 w-4/5 md:h-48 bg-contain bg-no-repeat bg-center'
              style={{ backgroundImage: "url('/images/mac1.jpg')" }}
            ></div>
            <div className='absolute bottom-0 w-full h-8 md:h-16 bg-gradient-to-t from-gray-800 to-transparent'></div>
          </div>
          <div className='w-full h-full pb-32 bg-gray-800 mt-36 z-10'>
            <div className='bg-white py-6 sm:py-8 lg:py-12'>
              <div className='mx-auto max-w-screen-2xl px-4 md:px-8'>
                <h2 className='mb-4 text-center text-2xl font-bold text-gray-800 md:mb-8 lg:text-3xl xl:mb-12'>
                  Recent Collections
                </h2>
                <SelectedWorkArt />
                <div className='flex items-start justify-between gap-8 sm:items-center'>
                  <p className='max-w-screen-sm text-sm text-gray-500 lg:text-base'>
                    View more in the{' '}
                    <Link to='/galleries' className='text-gray-800 hover:underline'>
                      Collections
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full h-full pb-32 bg-gray-800 text-white flex flex-col justify-center items-center z-10'>
            <LetsMakeSomethingTogether />
          </div>
        </Suspense>
      )}
    </div>
  );
};

export default Home;
