import React, { useState } from "react";

const TermsAndConditions = ({ termsAccepted, handleTermsChange }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="mt-4">
      {/* Checkbox & Terms Link */}
      <label className="flex items-center space-x-2 cursor-pointer">
        <input
          type="checkbox"
          checked={termsAccepted}
          onChange={handleTermsChange}
          className="w-5 h-5 text-gray-600 border-gray-300 rounded focus:ring focus:ring-gray-500"
        />
        <span className={`text-sm ${termsAccepted ? "text-gray-600" : "text-gray-700"}`}>
          I agree to the{" "}
          <button
            onClick={() => setOpen(true)}
            className="text-blue-600 hover:underline focus:outline-none"
          >
            terms and conditions
          </button>
        </span>
      </label>

      {/* Tailwind-Based Modal */}
      {open && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-lg p-6">
            <h2 className="text-xl font-semibold mb-4 text-gray-900">
              Terms and Conditions
            </h2>
            <div className="text-gray-600 text-sm h-64 overflow-y-auto border p-3 rounded-md">
              <p className="mb-4">
                <strong>Effective Date:</strong> 07/01/2024
              </p>
              <p>
                Welcome to Macaulay Takes Pictures. By booking my photography
                services, you agree to the following terms and conditions:
              </p>

              <h3 className="font-semibold mt-4">1. Booking and Payment</h3>
              <p>1.1 A non-refundable deposit of 20% is required to secure your booking.</p>
              <p>1.2 Payments can be made via Venmo/Paypal/Cash.</p>

              <h3 className="font-semibold mt-4">2. Cancellation and Rescheduling</h3>
              <ul className="list-disc ml-6">
                <li>
                  More than 1 day before the scheduled date: deposit forfeited, additional payments refunded.
                </li>
                <li>Less than 1 day: No refund.</li>
              </ul>

              <h3 className="font-semibold mt-4">3. Usage Rights</h3>
              <p>3.1 Client receives personal usage rights for final images.</p>
              <p>3.2 No commercial use without prior written consent.</p>

              <h3 className="font-semibold mt-4">4. Model Release</h3>
              <p>4.1 The Client grants me the right to use the images for promotional purposes unless they opt out in writing.</p>

              <h3 className="font-semibold mt-4">5. Liability</h3>
              <p>5.1 I am not liable for any injuries, damages, or losses incurred by the Client during the session.</p>

              <h3 className="font-semibold mt-4">6. Miscellaneous</h3>
              <p>6.1 This agreement constitutes the entire agreement between the parties.</p>

              <h3 className="font-semibold mt-4">Contact Information</h3>
              <p>
                <strong>Macaulay Takes Pictures</strong>
                <br />
                macaulayTakesPictures@gmail.com
              </p>
            </div>

            {/* Close Button */}
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setOpen(false)}
                className="px-4 py-2 text-white bg-gray-600 rounded-md hover:bg-gray-800 transition"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsAndConditions;