import { useEffect, useState, useContext, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Masonry from "react-masonry-css";

const GalleryDetail = () => {
  const { galleryId } = useParams();
  const { user } = useContext(AuthContext);
  const [gallery, setGallery] = useState(null);
  const [images, setImages] = useState([]);
  const [error, setError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };
  const [isPublic, setIsPublic] = useState(false);
  const API_URL = process.env.REACT_APP_API_BASE_URL;

  const fetchGalleryImages = useCallback(async () => {
    try {
      const imagesRes = await axios.get(
        API_URL + `/api/galleries/${galleryId}/images`
      );
      setImages(imagesRes.data.images || []);
    } catch (err) {
      console.error(
        "❌ Error fetching images:",
        err.response?.data || err.message
      );
    }
  }, [galleryId, API_URL]);

  useEffect(() => {
    const fetchGalleryData = async () => {
      try {
        const token = localStorage.getItem("token");
        const galleryRes = await axios.get(
          API_URL + `/api/galleries/${galleryId}`,
          {
            headers: token ? { Authorization: `Bearer ${token}` } : {},
          }
        );
        setGallery(galleryRes.data.gallery);
        setIsPublic(galleryRes.data.gallery.is_public); // ✅ Restore public/private state
        fetchGalleryImages(); // ✅ Now stable
      } catch (err) {
        console.error(
          "❌ Error fetching gallery:",
          err.response?.data || err.message
        );
        setError("Failed to load gallery.");
      }
    };

    fetchGalleryData();
  }, [galleryId, fetchGalleryImages, API_URL]);

  // ✅ Handle Drag & Drop Multiple Image Uploads
  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);

    const files = [...event.dataTransfer.files]; // Get all dropped files

    if (files.length > 0) {
      handleMultipleImageUpload(files);
    }
  };

  const breakpointColumnsObj = {
    default: 4, // 4 columns on large screens
    1100: 3,
    700: 2,
    500: 1,
  };

  const handleMultipleImageUpload = async (files) => {
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const validFiles = files.filter((file) => allowedTypes.includes(file.type));

    if (validFiles.length === 0) {
      setError(
        "❌ No valid images found. Only JPG, JPEG, and PNG are allowed."
      );
      return;
    }

    setUploading(true);
    setUploadProgress(0); // Start at 0%
    setError(null);

    // ✅ Show a preview of the first image
    const firstFile = validFiles[0];
    const previewURL = URL.createObjectURL(firstFile);
    setPreviewImage(previewURL);

    try {
      const token = localStorage.getItem("token");
      const batchSize = 3; // ✅ Adjust batch size
      let uploaded = 0;

      for (let i = 0; i < validFiles.length; i += batchSize) {
        const batch = validFiles.slice(i, i + batchSize); // Get batch of 3 images

        const uploadPromises = batch.map(async (file) => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("galleryId", galleryId);

          return axios.post(API_URL + "/api/upload", formData, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(
                (progressEvent.loaded / progressEvent.total) * 100
              );
              setUploadProgress(progress);
            },
          });
        });

        await Promise.all(uploadPromises);
        uploaded += batch.length;

        setUploadProgress(Math.round((uploaded / validFiles.length) * 100));

        await fetchGalleryImages();
      }

      setUploadProgress(100);
    } catch (err) {
      console.error(
        "❌ Error uploading images:",
        err.response?.data || err.message
      );
      setError("Failed to upload some images.");
    } finally {
      setUploading(false);
    }
  };

  const toggleGalleryPrivacy = async () => {
    try {
      const token = localStorage.getItem("token");
      const newPrivacyStatus = !isPublic;

      await axios.post(
        API_URL + `/api/galleries/toggle-privacy`,
        { galleryId, isPublic: newPrivacyStatus },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setIsPublic(newPrivacyStatus);
    } catch (err) {
      console.error(
        "❌ Error updating gallery privacy:",
        err.response?.data || err.message
      );
      setError("Failed to update privacy settings.");
    }
  };

  const isGalleryOwner = user && gallery?.user_id === user.id;

  return (
    <div className="p-4 sm:p-6 max-w-4xl w-full mx-auto my-12 sm:my-24">
      {error && <p className="text-red-500 text-sm sm:text-base">{error}</p>}

      {gallery ? (
        <>
          <div className="text-center my-4 sm:my-6">
            <h1 className="text-2xl sm:text-3xl text-gray-800 font-bold">
              {gallery.title}
            </h1>
            <p className="text-gray-500 text-sm sm:text-base">
              {gallery.description}
            </p>
          </div>

          {isGalleryOwner ? (
            <Link
              to="/Dashboard"
              className="text-gray-800 hover:underline text-sm sm:text-base"
            >
              ← Back to Dashboard
            </Link>
          ) : (
            <Link
              to="/Galleries"
              className="text-gray-800 hover:underline text-sm sm:text-base"
            >
              ← Back to Collections
            </Link>
          )}

          {/* ✅ Image Upload (Only for Gallery Owner) */}
          {isGalleryOwner && (
            <div className="mt-4 bg-gray-100 p-4 sm:p-6 rounded-lg shadow-md">
              {/* Drag-and-Drop Upload */}
              <div
                className={`border-2 border-dashed rounded-lg p-4 sm:p-6 text-center ${
                  dragging ? "border-gray-500" : "border-gray-400"
                }`}
                onDragOver={(e) => {
                  e.preventDefault();
                  setDragging(true);
                }}
                onDragLeave={() => setDragging(false)}
                onDrop={handleDrop}
              >
                {dragging ? (
                  <p className="text-gray-500 text-sm sm:text-base">
                    Drop your images here...
                  </p>
                ) : (
                  <p className="text-sm sm:text-base">
                    Drag & Drop multiple images or click to upload
                  </p>
                )}
                <input
                  type="file"
                  accept="image/jpeg, image/jpg, image/png"
                  multiple
                  onChange={(e) =>
                    handleMultipleImageUpload([...e.target.files])
                  }
                  disabled={uploading}
                  className="hidden"
                />
              </div>

              {uploading && (
                <div className="mt-2">
                  <p className="text-gray-800 text-sm">
                    Uploading: {uploadProgress}%
                  </p>
                  <div className="w-full bg-gray-200 rounded">
                    <div
                      className="bg-gray-800 text-xs leading-none py-1 text-center text-white"
                      style={{ width: `${uploadProgress}%` }}
                    >
                      {uploadProgress}%
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-col sm:flex-row items-center justify-center gap-3 mt-4">
                <span className="text-gray-600 text-sm">Gallery Privacy:</span>
                <button
                  onClick={toggleGalleryPrivacy}
                  className={`px-4 py-2 text-sm sm:text-base rounded-md text-white ${
                    isPublic
                      ? "bg-green-500 hover:bg-green-600"
                      : "bg-gray-500 hover:bg-gray-600"
                  }`}
                >
                  {isPublic ? "Public" : "Private"}
                </button>
              </div>

              {/* Image Preview */}
              {previewImage && (
                <div className="mt-4 flex justify-center">
                  <img
                    src={previewImage}
                    alt="Preview"
                    className="w-24 h-24 sm:w-32 sm:h-32 object-cover rounded-lg shadow"
                  />
                </div>
              )}
            </div>
          )}

          {/* ✅ Mobile-Friendly Gallery Grid */}
          <div className="mt-6">
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="flex -ml-4 w-auto" // -ml-4 offsets the column spacing
              columnClassName="pl-4 bg-clip-padding" // pl-4 re-adds spacing to each column
            >
              {images.map((image, index) => (
                <div
                  key={image.id}
                  className="mb-4 break-inside-avoid overflow-hidden rounded-lg group"
                  onClick={() => openLightbox(index)}
                >
                  <img
                    src={`https://imagedelivery.net/Z1o5jP3Z0wrLOM-5XDvR8A/${image.id}/thumbnail`}
                    alt={`Gallery Img ${image.id}`}
                    className="w-full h-auto object-contain transition-transform duration-300
                     transform group-hover:scale-105 cursor-pointer"
                  />
                </div>
              ))}
            </Masonry>
          </div>

          {/* ✅ Lightbox for Full-Size Images */}
          {lightboxOpen && (
            <Lightbox
              open={lightboxOpen}
              close={() => setLightboxOpen(false)}
              slides={images.map((image) => ({
                src: `https://imagedelivery.net/Z1o5jP3Z0wrLOM-5XDvR8A/${image.id}/SelectedArt2`,
              }))}
              index={lightboxIndex}
              controller={{ closeOnBackdropClick: true }}
              on={{ view: ({ index }) => setLightboxIndex(index) }}
            />
          )}
        </>
      ) : (
        <p className="text-center text-sm sm:text-base">Loading gallery...</p>
      )}
    </div>
  );
};

export default GalleryDetail;
