import React, { useState } from "react";
import emailjs from "emailjs-com";
import TermsAndConditions from "../components/TermsAndConditions";

const PhotoSessionPage = () => {
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [notes, setNotes] = useState("");
  const [emailError, setEmailError] = useState("");
  const [submitError, setSubmitError] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setEmailError("Invalid email address");
      return;
    }

    setEmailError("");

    const emailContent = {
      from_name: name,
      from_email: email,
      message: notes,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        emailContent,
        process.env.REACT_APP_EMAILJS_USER_ID
      )
      .then(() => {
        setShowSuccess(true);
      })
      .catch(() => {
        setSubmitError("Failed to send email. Please try again.");
        setShowError(true);
      });
  };

  return (
    <div className="min-h-screen mt-20 flex flex-col items-center justify-center py-12 px-6">
      <div className="w-full max-w-2xl text-center">
        <div className="bg-gray-800 text-white rounded-lg shadow-lg py-8 px-6">
          <h1 className="text-3xl font-extrabold">Photo Session</h1>
        </div>
      </div>

      <div className="w-full max-w-2xl bg-white shadow-lg rounded-lg mt-8 p-6">
        <h2 className="text-2xl font-bold mb-4">Details</h2>
        <ul className="list-disc list-inside text-gray-600 space-y-2">
          <li>60-minute session</li>
          <li>Hand editing</li>
          <li>All edited images included</li>
          <li>Multiple Locations</li>
          <li>Outfit Changes</li>
          <li className="font-semibold text-lg text-gray-800">$150</li>
        </ul>
        <div className="mt-4 p-4 bg-gray-100 text-gray-800 rounded-md">
          📍 If travel exceeds 30 minutes, an additional <b>$20</b> fee will be added.
        </div>
      </div>

      <div className="w-full max-w-2xl bg-white shadow-lg rounded-lg mt-8 p-6">
        <h2 className="text-2xl font-bold mb-4">Book Your Session</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              maxLength="50"
              required
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              maxLength="100"
              required
              className={`w-full p-3 border ${
                emailError ? "border-red-500" : "border-gray-300"
              } rounded-md focus:ring-2 focus:ring-gray-500`}
            />
            {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Additional Notes</label>
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              maxLength="500"
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-2 focus:ring-gray-500"
            />
            <p className="text-sm text-gray-500 mt-1">
              Locations, special occasions, event, type of photography, etc.
            </p>
          </div>

          <div className="mb-4">
            <TermsAndConditions termsAccepted={termsAccepted} handleTermsChange={handleTermsChange} />
          </div>

          <button
            type="submit"
            className={`w-full text-white font-semibold py-3 rounded-md ${
              termsAccepted ? "bg-gray-600 hover:bg-gray-800" : "bg-gray-400 cursor-not-allowed"
            }`}
            disabled={!termsAccepted}
          >
            Submit
          </button>
        </form>
      </div>

      {showSuccess && (
        <div className="fixed bottom-6 bg-green-500 text-white py-2 px-6 rounded-md shadow-lg">
          ✅ Email sent successfully!
          <button onClick={() => setShowSuccess(false)} className="ml-4">×</button>
        </div>
      )}
      {showError && (
        <div className="fixed bottom-6 bg-red-500 text-white py-2 px-6 rounded-md shadow-lg">
          ❌ {submitError}
          <button onClick={() => setShowError(false)} className="ml-4">×</button>
        </div>
      )}
    </div>
  );
};

export default PhotoSessionPage;