import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL

const Dashboard = () => {
  const [galleries, setGalleries] = useState([]);
  const [error, setError] = useState(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserGalleries = async () => {
      try {
        const res = await axios.get(API_URL + "/api/galleries/user", {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Fetch the first image of each gallery to use as a cover image
        const galleriesWithThumbnails = await Promise.all(
          res.data.galleries.map(async (gallery) => {
            try {
              const imagesRes = await axios.get(
                API_URL + `/api/galleries/${gallery.id}/images`
              );
              const coverImage = imagesRes.data.images.length > 0 ? imagesRes.data.images[0].thumbnailUrl : null;
              return { ...gallery, coverImage };
            } catch {
              return { ...gallery, coverImage: null };
            }
          })
        );

        setGalleries(galleriesWithThumbnails);
      } catch (err) {
        console.error("❌ Error fetching galleries:", err.response?.data || err.message);
        setError("Failed to load your galleries.");
      }
    };

    fetchUserGalleries();
  }, [token]);

  const handleDeleteGallery = async (galleryId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this gallery? This action cannot be undone.");
    if (!confirmDelete) return;

    try {
      const res = await axios.delete(API_URL + `/api/galleries/${galleryId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (res.status === 200) {
        setGalleries((prevGalleries) => prevGalleries.filter((gallery) => gallery.id !== galleryId));
        console.log(`✅ Gallery ${galleryId} deleted successfully`);
      }
    } catch (err) {
      console.error("❌ Error deleting gallery:", err.response?.data || err.message);
      alert("Failed to delete the gallery.");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="p-6 max-w-6xl mx-auto my-32">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Your Collections</h1>
        <button
          onClick={handleLogout}
          className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
        >
          Logout
        </button>
      </div>

      {/* Create Gallery Button */}
      <div className="mb-6 text-right">
        <Link
          to="/create-gallery"
          className="bg-blue-500 text-white px-5 py-2 rounded-md hover:bg-blue-600 transition inline-block"
        >
          + Create Collection
        </Link>
      </div>

      {/* Error Message */}
      {error && <p className="text-red-500 text-center">{error}</p>}

      {/* No Galleries Message */}
      {galleries.length === 0 ? (
        <p className="mt-6 text-center text-gray-500 text-lg">No galleries found.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {galleries.map((gallery) => (
            <div key={gallery.id} className="relative group overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition">
              <Link to={`/gallery/${gallery.id}`} className="block relative w-full" style={{ aspectRatio: "3 / 2" }}>
                {/* Background Image */}
                {gallery.coverImage ? (
                  <img
                    src={gallery.coverImage}
                    alt={gallery.title}
                    className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 transform scale-100 group-hover:scale-105"
                    loading="lazy"
                  />
                ) : (
                  <div className="absolute inset-0 w-full h-full bg-gray-300 flex items-center justify-center text-gray-600">
                    No Cover Image
                  </div>
                )}
                {/* Gradient Overlay */}
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
                {/* Gallery Title - Centered */}
                <h2 className="absolute inset-0 flex items-center drop-shadow-lg justify-center text-white text-lg font-semibold text-center px-2">
                  {gallery.title}
                </h2>
              </Link>
              {/* Delete Button */}
              <button
                onClick={() => handleDeleteGallery(gallery.id)}
                className="absolute top-3 right-3 bg-red-500 text-white text-sm px-2 py-1 rounded opacity-75 hover:opacity-100 transition"
                title="Delete Gallery"
              >
                ✕
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dashboard;