import React from 'react';
import { Link } from 'react-router-dom';

const LetsMakeSomethingTogether = () => {
  return (
    <section className='overflow-hidden'>
      <div className='mx-auto max-w-screen px-4 py-16 sm:px-6 lg:px-8'>
        <div className='grid grid-cols-1 lg:h-screen lg:grid-cols-2'>
          <div className='relative z-10 lg:py-16'>
            <div className='relative h-96 sm:h-80 lg:h-full'>
              <img
                alt=''
                src='https://i.imgur.com/qjoPZJSh.jpg'
                className='absolute inset-0 h-full w-full object-cover'
              />
            </div>
          </div>

          <div className='relative flex items-center bg-gray-100'>
            <span className='hidden lg:absolute lg:inset-y-0 lg:-start-16 lg:block lg:w-16 lg:bg-gray-100'></span>

            <div className='p-8 sm:p-16 lg:p-24'>
              <h2 className='text-2xl text-gray-800 font-bold sm:text-3xl'>
                Let's make something awesome together.
              </h2>

              <p className='mt-4 text-gray-800'>
                Capture the moments that matter with a personalized photography
                session, specializing in sports photography. Whether it's a
                family portrait, or a special occasion, my goal is to create
                stunning images that you'll cherish forever. I have a passion
                for capturing the thrill and excitement of sports. Let's work
                together to bring your vision to life. Book your session today
                and let your memories come to life through my lens.
              </p>

              <Link
                to='/PhotoSessionPage'
                className='mt-8 inline-block rounded border text-center border-gray-800 bg-gray-800 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-gray-800 focus:outline-none focus:ring active:text-gray-800'
              >
                Book Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LetsMakeSomethingTogether;
