import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL;
const useFetchPricingImages = () => {
  const [images, setImages] = useState({ photoSession: "", sportsSession: "" });

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const res = await axios.get(API_URL + "/api/pricing-images");
        const fetchedImages = res.data.images;

        setImages({
          photoSession:
            fetchedImages.find(
              (img) => img.id === "f718a94a-98eb-45df-c38b-e476b1b5c500"
            )?.url || "",
          sportsSession:
            fetchedImages.find(
              (img) => img.id === "39dcba6d-b24c-4b84-b746-4dd4a7bac800"
            )?.url || "",
        });
      } catch (err) {
        console.error("❌ Error fetching pricing images:", err.message);
      }
    };

    fetchImages();
  }, []);

  return images;
};

const PhotographyServices = () => {
  const { photoSession, sportsSession } = useFetchPricingImages();

  return (
    <div className="items-center overflow-hidden justify-center min-h-screen bg-gray-100 text-gray-800 py-8 px-4 sm:px-8">
      <div className="max-w-screen-lg mx-auto my-20">
        {/* Header Section */}
        <div className="w-full xs:h-28 h-64 relative overflow-hidden mb-10 bg-gray-800 flex flex-col items-center justify-center rounded-lg">
          <div className="absolute inset-0 w-full h-full bg-gray-800 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none" />
          <div className="max-w-screen-md mx-auto">
            <motion.h1
              className="md:text-4x1 font-extrabold text-4xl text-white relative z-20"
              initial={{ opacity: 0, y: -20 }} // Initial state
              animate={{ opacity: 1, y: 0 }} // Animated state
              transition={{ duration: 1 }} // Transition duration
            >
              Pricing
            </motion.h1>
          </div>
        </div>

        <div className="max-w-5xl w-full space-y-16">
          {/* Photo Session Section */}
          <div className="flex flex-col md:flex-row items-center md:space-x-10 bg-white shadow-lg rounded-lg overflow-hidden p-6">
            <img
              src={photoSession}
              alt="Session"
              className="w-full md:w-1/2 h-auto object-cover rounded-lg"
            />
            <div className="md:w-1/2 md:text-left">
              <h2 className="text-2xl text-center font-bold mb-4">
                Photo Session
              </h2>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>60-minute session</li>
                <li>Hand editing</li>
                <li>All edited images included</li>
                <li>Multiple Locations</li>
                <li>Outfit Changes</li>
              </ul>
              <p className="text-lg font-semibold mb-4">
                Starting at <span className="text-gray-800">$150</span>
              </p>
              <p
                className="bg-gray-100 text-gray-800 px-6 sm:px-6 py-3 sm:py-4 rounded-md 
    mb-6 sm:mb-8 mt-6 md:max-w-[90%] lg:max-w-[85%]"
              >
                📍 If travel exceeds 30 minutes, an additional <b>$20</b> fee will be added
              </p>
              <Link
                to="/PhotoSession"
                className="bg-gray-800 text-white px-6 py-3 rounded-lg hover:bg-gray-700 transition"
              >
                Book Now
              </Link>
            </div>
          </div>

          {/* Sports Session Section */}
          <div className="flex flex-col md:flex-row-reverse items-center md:space-x-10 bg-white shadow-lg rounded-lg overflow-hidden p-6">
            <img
              src={sportsSession}
              alt="Sports Session"
              className="w-full md:w-1/2 h-auto object-cover rounded-lg"
            />
            <div className="md:w-1/2 md:text-left">
              <h2 className="text-2xl text-center font-bold mb-4">
                Sports Session
              </h2>
              <ul className="list-disc list-inside text-gray-700 space-y-1">
                <li>One sports event</li>
                <li>Hand editing</li>
                <li>All edited images included</li>
                <li>No limit on delivered images</li>
                <li>Professional Stills</li>
              </ul>
              <p className="text-lg font-semibold mb-4">
                Starting at <span className="text-gray-800">$200</span> +
                $100/hr
              </p>
              <p
                className="bg-gray-100 text-gray-800 px-6 sm:px-6 py-3 sm:py-4 rounded-md 
    mb-6 sm:mb-8 mt-6 md:max-w-[90%] lg:max-w-[85%]"
              >
                📍 If travel exceeds 30 minutes, an additional <b>$20</b> fee will be added
              </p>
              <Link
                to="/SportsSession"
                className="bg-gray-800 text-white px-6 py-3 rounded-lg hover:bg-gray-700 transition"
              >
                Book Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotographyServices;
