import { useEffect, useState, Suspense } from "react";
import { Link } from "react-router-dom";
import Loading from "../components/Loading";
import axios from "axios";
import { motion } from "framer-motion";

const API_URL = process.env.REACT_APP_API_BASE_URL
const PublicGalleries = () => {
  const [isPending, setIsPending] = useState(true);
  const [galleries, setGalleries] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPublicGalleries = async () => {
      try {
        const res = await axios.get(
          API_URL + "/api/galleries/public"
        );

        if (!res.data.galleries || res.data.galleries.length === 0) {
          console.warn("⚠️ No public galleries available.");
        }

        const galleriesWithThumbnails = await Promise.all(
          res.data.galleries.map(async (gallery) => {
            try {
              const imagesRes = await axios.get(
                API_URL + `/api/galleries/${gallery.id}/images`
              );
              const coverImage =
                imagesRes.data.images.length > 0
                  ? imagesRes.data.images[0].thumbnailUrl
                  : null;
              return { ...gallery, coverImage };
            } catch {
              return { ...gallery, coverImage: null };
            }
          })
        );

        setGalleries(galleriesWithThumbnails);
      } catch (err) {
        console.error(
          "❌ Error fetching public galleries:",
          err.response?.data || err.message
        );
        setError("Failed to load public galleries.");
      }
    };

    fetchPublicGalleries();

    const timer = setTimeout(() => {
      setIsPending(false);
    }); // Simulate loading state`

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="items-center overflow-hidden justify-center min-h-screen bg-gray-100 text-gray-800 py-8 px-4 sm:px-8">
      <div className="max-w-screen-lg mx-auto my-20">
        {/* Header Section */}
        <div className="w-full xs:h-28 h-64 relative overflow-hidden mb-10 bg-gray-800 flex flex-col items-center justify-center rounded-lg">
          <div className="absolute inset-0 w-full h-full bg-gray-800 z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none" />
          <div className="max-w-screen-md mx-auto">
          <motion.h1
              className="md:text-4x1 font-extrabold text-4xl text-white relative z-20"
              initial={{ opacity: 0, y: -20 }} // Initial state
              animate={{ opacity: 1, y: 0 }} // Animated state
              transition={{ duration: 1 }} // Transition duration
            >
              Collections
            </motion.h1>
          </div>
        </div>      
      {isPending && <Loading />}
      {!isPending && (
        <Suspense fallback={<Loading />}>
          <div className="py-5">
            <Link to="/" className="text-gray-800 hover:underline">
              ← Back to Home
            </Link>
          </div>

          {error && <p className="text-red-500">{error}</p>}

          {/* No Galleries Message */}
          {galleries.length === 0 ? (
            <p className="mt-6 text-center text-gray-500 text-lg">
              No galleries found.
            </p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
              {galleries.map((gallery) => (
                <div
                  key={gallery.id}
                  className="relative group overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition"
                >
                  <Link
                    to={`/gallery/${gallery.id}`}
                    className="block relative w-full"
                    style={{ aspectRatio: "3 / 2" }}
                  >
                    {gallery.coverImage ? (
                      <img
                        src={gallery.coverImage}
                        alt={gallery.title}
                        className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 transform scale-100 group-hover:scale-105"
                        loading="lazy"
                      />
                    ) : (
                      <div className="absolute inset-0 w-full h-full bg-gray-300 flex items-center justify-center text-gray-600">
                        No Cover Image
                      </div>
                    )}
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
\                    <h2 className="absolute inset-0 flex items-center drop-shadow-lg justify-center text-white text-lg font-semibold text-center px-2">
                      {gallery.title}
                    </h2>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </Suspense>
      )}
    </div>
    </div>
  );
};

export default PublicGalleries;
