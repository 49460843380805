import React, { useEffect, useState, Suspense } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Loading from "../components/Loading";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL + "/api/about-images";
const BEARER = process.env.REACT_APP_BEARER;

const TARGET_IMAGE_IDS = [
  "e7b87ac9-0a40-41e0-2b75-89cb72ddcf00", // Snowboarding
  "47e335c1-4924-4951-b4a3-f94b37e5dc00", // Grad Photo
  "b5008045-5189-4d9f-91bc-2a1742791b00", // Utah
];

const AboutComponent = () => {
  const [isPending, setIsPending] = useState(true);
  const [images, setImages] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        console.log("🔍 Fetching images from API:", API_URL);

        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${BEARER}`,
          },
        });

        console.log("✅ API Response:", response.data);

        if (response.data?.images) {
          console.log("📸 Images Found in API Response:", response.data.images);

          const imageMap = response.data.images.reduce((acc, image) => {
            if (TARGET_IMAGE_IDS.includes(image.id)) {
              acc[image.id] = image.url; // ✅ Fix: Directly use `image.url`
              console.log(`✅ Image Mapped: ${image.id} -> ${image.url}`);
            } else {
              console.warn(`⚠️ Image ID not in TARGET_IMAGE_IDS: ${image.id}`);
            }
            return acc;
          }, {});

          setImages(imageMap);
        } else {
          console.error("❌ Unexpected API response structure:", response.data);
          setError("Unexpected API response format.");
        }
      } catch (error) {
        console.error("❌ Error fetching images:", error);
        setError("Failed to load images.");
      }
    };

    fetchImages();

    const timer = setTimeout(() => {
      setIsPending(false);
    }, 1000); // Simulate loading state

    return () => clearTimeout(timer);
  }, []);

  return (
    <section className="overflow-hidden pt-20 pb-12 lg:pt-[120px] lg:pb-[90px] bg-white dark:bg-dark">
      {isPending && <Loading />}
      {!isPending && (
        <Suspense fallback={<Loading />}>
          <div className="container mx-auto">
            {error && (
              <div className="mb-4 text-center text-red-600 bg-red-100 border border-red-400 p-3 rounded-lg">
                {error}
              </div>
            )}

            <div className="flex flex-wrap items-center justify-between mt-10 -mx-4">
              <div className="w-full px-4 lg:w-6/12">
                <div className="flex items-center -mx-3 sm:-mx-4">
                  <div className="w-full px-3 sm:px-4 xl:w-1/2">
                    <div className="py-3 sm:py-4 group">
                      <LazyLoadImage
                        src={
                          images["e7b87ac9-0a40-41e0-2b75-89cb72ddcf00"] ||
                          "placeholder.jpg"
                        }
                        alt="Snowboarding"
                        className="w-full rounded-2xl"
                        effect="blur"
                      />
                    </div>
                    <div className="py-3 sm:py-4 group">
                      <LazyLoadImage
                        src={
                          images["47e335c1-4924-4951-b4a3-f94b37e5dc00"] ||
                          "placeholder.jpg"
                        }
                        alt="Grad Photo"
                        className="w-full rounded-2xl"
                        effect="blur"
                      />
                    </div>
                  </div>
                  <div className="w-full px-3 sm:px-4 xl:w-1/2">
                    <div className="relative z-10 my-4 group">
                      <LazyLoadImage
                        src={
                          images["b5008045-5189-4d9f-91bc-2a1742791b00"] ||
                          "placeholder.jpg"
                        }
                        alt="Utah"
                        className="w-full rounded-2xl"
                        effect="blur"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full px-4 lg:w-1/2 xl:w-5/12">
                <div className="mt-10 lg:mt-0">
                  <span className="block mb-4 text-lg font-semibold text-primary">
                    Macaulay Koontz
                  </span>
                  <h2 className="mb-5 text-3xl font-bold text-dark dark:text-gray-800 sm:text-[40px]/[48px]">
                    An engineer, developer, and growing photographer
                  </h2>
                  <p className="mb-5 text-lg text-body-color dark:text-dark-6">
                    Hey there! I'm Macaulay, an aspiring photographer born in
                    Dodge City, currently living in the Kansas City area. I
                    graduated from Platte County High School, class of 2018.
                    Then, I received my bachelor's degree in Computer Science at
                    Missouri Western State University. When I'm not working as
                    an engineer, you'll find me traveling and exploring new
                    places. I'm all about capturing those spontaneous,
                    action-packed moments with my camera.
                  </p>
                  <p className="mb-8 text-lg text-body-color dark:text-dark-6">
                    I'm always on the hunt for my next great shot. With my
                    diverse skills and passion, I want to capture the world
                    through my lens and provide a unique perspective to the
                    images I create. I aim to expand my portfolio with work that
                    I am truly proud of and excited to share with others.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Suspense>
      )}
    </section>
  );
};

const About = React.lazy(() => Promise.resolve({ default: AboutComponent }));

const AboutPage = () => (
  <React.Suspense fallback={<div>Loading...</div>}>
    <About />
  </React.Suspense>
);

export default AboutPage;
