import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import CreateGallery from "./pages/CreateGallery";
import GalleryDetail from "./pages/GalleryDetail";
import PublicGalleries from "./pages/publicGalleries";
import ProtectedRoute from "./components/ProtectedRoute";
import Loading from "./components/Loading";
import { AuthProvider } from "./context/AuthContext";
import Navbar from "./components/Navbar";
import Footer from "./components/footer";
import AboutPage from "./pages/about";
import PricingPage from "./pages/Pricing";
import PhotoSession from "./pages/PhotoSession"
import SportsSession from "./pages/SportsSession"
import NotFound from "./pages/NotFound";


function App() {
  return (
    <Router>
      {" "}
      <AuthProvider>
        <Navbar />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />{" "}
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={ <ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/create-gallery" element={ <ProtectedRoute><CreateGallery /></ProtectedRoute> }/>
            <Route path="/gallery/:galleryId" element={<GalleryDetail />} />
            <Route path="/galleries" element={<PublicGalleries />} />
            <Route path="/About" element={<AboutPage />} />
            <Route path="/Pricing" element={<PricingPage />} />
            <Route path="/PhotoSession" element={<PhotoSession/>} />
            <Route path="/SportsSession" element={<SportsSession/>} />
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </Suspense>
        <Footer />
      </AuthProvider>
    </Router>
  );
}

export default App;
