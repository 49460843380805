import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL

const SelectedWorkArt = () => {
  const [galleries, setGalleries] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecentGalleries = async () => {
      try {
        const res = await axios.get(API_URL + "/api/galleries/public");

        if (!res.data.galleries || res.data.galleries.length === 0) {
          console.warn("⚠️ No galleries returned from API.");
        } else {
        }

        const recentGalleries = res.data.galleries.slice(0, 4);

        const galleriesWithImages = await Promise.all(
          recentGalleries.map(async (gallery) => {
            try {
              const imagesRes = await axios.get(
                API_URL + `/api/galleries/${gallery.id}/images`
              );

              const images = imagesRes.data.images;

              if (!images || images.length === 0) {
                console.warn(`⚠️ No images found for gallery: ${gallery.id}`);
                return { ...gallery, firstImage: null };
              }

              const firstImage = images[0]?.thumbnailUrl || images[0]?.url || null;

              return { ...gallery, firstImage };
            } catch (imageErr) {
              console.error(`❌ Error fetching images for gallery ${gallery.id}:`, imageErr.message);
              return { ...gallery, firstImage: null };
            }
          })
        );

        setGalleries(galleriesWithImages);
      } catch (err) {
        console.error("❌ Error fetching galleries:", err.response?.data || err.message);
        setError("Failed to load recent galleries.");
      }
    };

    fetchRecentGalleries();
  }, []);

  return (
    <div className="p-6 max-w-6xl mx-auto">
      {error && <p className="text-red-500 text-center">{error}</p>}

      {galleries.length === 0 ? (
        <p className="text-center text-gray-500 mt-8">No galleries available yet.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 auto-rows-[250px]">
          {galleries.map((gallery) => (
            <Link
              key={gallery.id}
              to={`/gallery/${gallery.id}`}
              className="relative group block overflow-hidden rounded-lg shadow-lg hover:shadow-2xl transition-transform duration-300"
            >
              {gallery.firstImage ? (
                <img
                  src={gallery.firstImage}
                  alt={gallery.title}
                  className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 transform scale-100 group-hover:scale-105 rounded-lg"
                  loading="lazy"
                />
              ) : (
                <div className="absolute inset-0 w-full h-full bg-gray-300 flex items-center justify-center text-gray-600 rounded-lg">
                  No Cover Image
                </div>
              )}

              <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent rounded-lg"></div>

              <h3 className="absolute inset-0 flex items-center justify-center text-white text-lg font-semibold text-center px-2">
                {gallery.title}
              </h3>
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectedWorkArt;